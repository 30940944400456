import axios from '@/axios/globalaxios'

export const findUserByGroupId = data =>{
    return axios({
        url: "/tradelab/courseGroup/findUserByGroupId",
        method: 'post',
        data:data
    })
}
// 移除用户
export const delGroupUser = data =>{
    return axios({
        url: "/tradelab/courseGroup/delGroupUser",
        method: 'post',
        data:data
    })
}
// 查询所有类型
export const findGroupRole = data =>{
    return axios({
        url: "/tradelab/courseGroup/findGroupRole",
        method: 'post',
        data:data
    })
}
// 修改小组权限
export const updGroupRole = data =>{
    return axios({
        url: "/tradelab/courseGroup/updGroupRole",
        method: 'post',
        data:data
    })
}
